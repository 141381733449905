import { AxiosInstance } from "../Axios/Axios"

const endpoints = {
    
    fetchBirthday: `/api/birthdays?populate=*&sort[0]=id`,
    fetchDecoration: `/api/decorations?populate=*&sort[0]=id`,
    fetchShow: `/api/shows?populate=*&sort[0]=id`,
    fetchSchool: `/api/back-to-schools?populate=*&sort[0]=id`,
    fetchHalloween: `/api/halloweens?populate=*&sort[0]=id`,
    fetchChristmas: `/api/christmases?populate=*&sort[0]=id`,
    fetchSummer: `/api/summers?populate=*&sort[0]=id`,
    fetchEaster: `/api/easters?populate=*&sort[0]=id`,
    fetchMizoMizaBirthday: `/api/mizo-miza-birthdays?populate=*&sort[0]=id`,
    fetchMizoMizaChristmas: `/api/mizo-miza-christmases?populate=*&sort[0]=id`,
    fetchMizoMizaEaster: `/api/mizo-miza-easters?populate=*&sort[0]=id`,
    fetchMizoMizaSummers: `/api/mizo-miza-summers?populate=*&sort[0]=id`,
    fetchMizoMizaHalloween: `/api/mizo-miza-halloweens?populate=*&sort[0]=id`,
    fetchMizoMizaPlay: `/api/mizo-miza-plays?populate=*&sort[0]=id`,
    fetchEvents: `/api/events?populate=*&sort[0]=id`,
    fetchBachelor: `/api/bachelors?populate=*&sort[0]=id`,
    fetchCommunion: `/api/communions?populate=*&sort[0]=id`,
    fetchBaptism: `/api/baptisms?populate=*&sort[0]=id`,
    fetchOpening: `/api/openings?populate=*&sort[0]=id`,
    fetchWedding: `/api/weddings?populate=*&sort[0]=id`,
    fetchRamadan: `/api/ramadans?populate=*&sort[0]=id`,
    fetchQatar: `/api/qatars?populate=*&sort[0]=id`,
    fetchBubble: `/api/bubble-shows?populate=*&sort[0]=id`,
    fetchScience: `/api/science-shows?populate=*&sort[0]=id`,
    fetchAnimal: `/api/animals?populate=*&sort[0]=id`,
    fetchStilt: `/api/stilt-shows?populate=*&sort[0]=id`,
    fetchClown: `/api/clown-shows?populate=*&sort[0]=id`,
    fetchMagic: `/api/magic-shows?populate=*&sort[0]=id`,

}

export const RequestManager = {
    async fetchBirthday() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBirthday)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchBubble() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBubble)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchScience() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchScience)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchAnimal() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchAnimal)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchStilt() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchStilt)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchClown() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchClown)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchMagic() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMagic)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchRamadan() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchRamadan)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchQatar() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchQatar)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchMizoMizaHalloween() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaHalloween)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchMizoMizaPlay() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaPlay)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchWedding() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchWedding)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchOpening() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchOpening)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchBaptism() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBaptism)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchCommunion() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchCommunion)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchBachelor() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchBachelor)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchDecoration() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchDecoration)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchShow() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchShow)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchSchool() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSchool)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchHalloween() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchHalloween)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchChristmas() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchChristmas)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchSummer() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchSummer)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchEaster() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchEaster)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchMizoMizaBirthday() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaBirthday)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchMizoMizaChristmas() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaChristmas)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },
    async fetchMizoMizaEaster() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaEaster)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchMizoMizaSummers() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchMizoMizaSummers)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },

    async fetchEvents() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchEvents)
            return response?.data
        } catch (error) {
            return {
                success: false,
                error,
            }
        }
    },


}