import './App.css';
import Birthdays from './Components/Birthdays/Birthdays';
import Contact from './Components/Contact/Contact';
import Education from './Components/Education/Education';
import Footer from './Components/Footer/Footer';
import Colonie from './Components/Colonie/Colonie'
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import { Routes, Route } from "react-router-dom"
import MizoMiza from './Components/MizoMiza/MizoMiza';


function App() {
  return (

    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/birthdays' element={<Birthdays />} />
        <Route path='/events' element={<Education />} />
        <Route path='/colonie' element={<Colonie />} />
        <Route path='/mizo-miza' element={<MizoMiza />} />
      </Routes>
      <Footer />
    </div>

  );
}

export default App;
