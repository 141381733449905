import React, { useState } from 'react'
import './Navbar.css'
import logo from '../../Components/Shared_Images/logo.png'
import { Sling as Hamburger } from 'hamburger-react'

function Navbar() {
    const [menuToggle, setMenuToggle] = useState(false);

    const toggleClick = () => {
        setMenuToggle(!menuToggle);

    }
    const MobileMenu = {
        direction: 'rtl',
        position: 'absolute',
        transition: '0.5s ease-in-out',
        right: 20,
        zIndex: '100',
        background: 'white',
        borderRadius: '20px',
        boxShadow: '0px 1px 0px 1px rgb(0,0,0, 0.1 )',
        padding: '20px',
        width: menuToggle? '50vw': '0vw',
        height: menuToggle? '50vh' : '0vh',
        opacity: menuToggle? '1' : '0',

    }

    return (
        <div className='container-fluid p-3 navigation' style={{ fontFamily: 'var(--primary)' }}>
            <div className="row d-flex  flex-nowrap align-items-center justify-content-center">
                <div className="col-auto d-none d-md-flex item" >
                    <a href="/" >
                        Home
                    </a>
                </div>
                <div className="col-auto d-none d-md-flex item">
                    <a href="/birthdays">
                        Birthdays
                    </a>
                </div>
                <div className="col-auto d-none d-md-flex item">
                    <a href="/mizo-miza">
                        Mizo & Miza
                    </a>
                </div>
                <div className="col-4 d-none d-md-none">
                    &nbsp;
                </div>
                <div className="col-4 col-md-2">
                    <a href="/">
                        <img src={logo} width="100%" alt="Smiley mizo Logo" />
                    </a>
                </div>
                <div className="col-auto item d-none d-md-flex">
                    <a href="/events">
                        Events
                    </a>
                </div>
                <div className="col-auto d-none d-md-flex item">
                    <a href="/colonie">

                        La Colonie
                    </a>
                </div>
                <div className="col-auto d-none d-md-flex item">
                    <a href="/contact">
                        Contact Us
                    </a>
                </div>

                <div onClick={() => toggleClick()} className="col-4 d-md-none d-flex align-content-center justify-content-end">
                  <Hamburger toggled={menuToggle} toggle={setMenuToggle} />
                </div>
            </div>
            <div className="row Menu" style={MobileMenu}>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/">
                        Home
                    </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/birthdays">
                        Birthdays
                    </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/mizo-miza">
                        Mizo & Miza
                    </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/events">
                        Events
                    </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/colonie">
                        La Colonie
                    </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <a href="/contact">
                        Contact Us
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Navbar