import React from 'react'
import './Contact.css'

function ContactCard(props) {
    return (
        <div className='crd h-100' style={{ backgroundColor: '#EDF8FE', borderRadius: '12px', fontSize: '18px' }}>
            <div className="py-5 px-4">
                <div className="row">
                    <div className="col-12 mb-3" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                        {props.Title}
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-1">
                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 0C6.95869 0 8.35764 0.607044 9.38909 1.68759C10.4205 2.76814 11 4.23367 11 5.76179C11 8.19652 9.36692 10.8499 6.14308 13.7503C5.96382 13.9116 5.73526 14.0002 5.49894 14C5.26262 13.9998 5.03422 13.9108 4.85523 13.7491L4.642 13.5553C1.56087 10.7305 0 8.14215 0 5.76179C0 4.23367 0.579463 2.76814 1.61091 1.68759C2.64236 0.607044 4.04131 0 5.5 0ZM5.5 3.54572C4.93897 3.54572 4.40091 3.7792 4.0042 4.19479C3.60749 4.61039 3.38462 5.17405 3.38462 5.76179C3.38462 6.34953 3.60749 6.9132 4.0042 7.32879C4.40091 7.74439 4.93897 7.97787 5.5 7.97787C6.06104 7.97787 6.59909 7.74439 6.9958 7.32879C7.39252 6.9132 7.61539 6.34953 7.61539 5.76179C7.61539 5.17405 7.39252 4.61039 6.9958 4.19479C6.59909 3.7792 6.06104 3.54572 5.5 3.54572Z" fill="black" />
                                </svg>

                            </div>
                            <div dangerouslySetInnerHTML={{__html: props.Address}} className="col-11">
                               
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-1">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9993 8.94667V11.304C11.9994 11.4728 11.9355 11.6353 11.8204 11.7588C11.7053 11.8823 11.5477 11.9575 11.3794 11.9693C11.0881 11.9893 10.8501 12 10.6661 12C4.77507 12 0 7.22467 0 1.33333C0 1.14933 0.00999945 0.911334 0.030665 0.62C0.0424792 0.451629 0.117714 0.294007 0.241189 0.178939C0.364664 0.0638712 0.527186 -7.62887e-05 0.695961 1.71053e-07H3.05316C3.13585 -8.35563e-05 3.21562 0.0305727 3.27697 0.0860136C3.33832 0.141455 3.37688 0.217722 3.38515 0.3C3.40048 0.453334 3.41448 0.575333 3.42781 0.668C3.56029 1.59262 3.83179 2.49189 4.2331 3.33533C4.29643 3.46867 4.2551 3.628 4.1351 3.71333L2.69652 4.74133C3.5761 6.79097 5.20941 8.42436 7.25893 9.304L8.28554 7.868C8.3275 7.80933 8.38872 7.76725 8.45853 7.7491C8.52833 7.73095 8.6023 7.73787 8.66752 7.76867C9.51081 8.16928 10.4098 8.44012 11.334 8.572C11.4267 8.58533 11.5487 8.59933 11.7007 8.61467C11.7828 8.62309 11.8589 8.66171 11.9142 8.72305C11.9695 8.78439 11.9994 8.86408 11.9993 8.94667Z" fill="black" />
                                </svg>


                            </div>
                            <div dangerouslySetInnerHTML={{__html: props.Number}} className="col-11">
                             
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.3 10C0.942501 10 0.636351 9.8775 0.381551 9.6325C0.126751 9.3875 -0.00043223 9.09333 1.10357e-06 8.75V1.25C1.10357e-06 0.906251 0.127401 0.611876 0.382201 0.366876C0.637001 0.121876 0.942934 -0.000415605 1.3 1.06112e-06H11.7C12.0575 1.06112e-06 12.3636 0.122501 12.6184 0.367501C12.8732 0.612501 13.0004 0.906668 13 1.25V8.75C13 9.09375 12.8726 9.38812 12.6178 9.63312C12.363 9.87812 12.0571 10.0004 11.7 10H1.3ZM6.5 5.625L11.7 2.5V1.25L6.5 4.375L1.3 1.25V2.5L6.5 5.625Z" fill="black" />
                                </svg>


                            </div>
                            <div className="col-11">
                                {props.Email}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard