import React, { useState, useEffect } from 'react'
import { RequestManager } from '../Request/Requests';
import TransformEvent from '../Transformer/TransformEvent';
import '../Home/Home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';

function Slider(props) {
    const [event, setEvent] = useState([]);

    useEffect(() => {
        const init = async () => {
            try {
                const info = await RequestManager.fetchEvents();
                const transformedInfo = TransformEvent(info?.data) ?? [];
                setEvent(transformedInfo)
            } catch (error) {
                console.error('Error fetching or transforming data:', error);
                setEvent([]);
            }
        };



        init();
    }, []);

    const renderEvent = (item, index) => {
        return (
            <SwiperSlide key={index}>
                <div className='row'>
                    <div className="col-12 col-lg-6" >
                        <div className="row">

                            <div className="col-12">

                                <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} alt="Birthdays and Events" width="100%" style={{ borderRadius: '25px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 p-5 d-flex align-items-center justify-content-center">
                        <div className="row">
                            <div className="col-12" style={{ fontSize: '16px' }}>
                                {item.Date}
                            </div>
                            <div className="col-12 Title2">
                                {item.Title}
                            </div>
                            <div className="col-12" style={{ fontSize: '18px' }}>
                                <p>
                                    {item.Description}
                                </p>
                            </div>
                            <div className="col-12">
                                <button className='Learn-button py-2 px-5'>
                                    <a href="/contact">
                                        CONTACT US
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        )
    }

    return (
        <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            pagination={{ clickable: true }}
        >
            {event.map(renderEvent)}
        </Swiper>
    )
}

export default Slider