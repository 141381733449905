import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Add a loading spinner (or use your preferred spinner component)
const Spinner = () => (
  <div className="spinner" style={{ textAlign: 'center', margin: '20px' }}>
    <div className="spinner-border" role="status">
   
    </div>
  </div>
);

function Images({ images }) {
  const [loading, setLoading] = useState(true); // Track the loading state
  const [loadedImages, setLoadedImages] = useState(0); // Track loaded images

  const handleImageLoad = () => {
    setLoadedImages(prev => prev + 1);
    if (loadedImages + 1 === images.length) {
      setLoading(false); // When all images are loaded, hide spinner
    }
  };

  return (
    <div className="col-12">
      {loading && <Spinner />} {/* Show spinner while loading */}
      <Swiper
        spaceBetween={30}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        modules={[Navigation, Pagination, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={`${process.env.REACT_APP_API_URL}${item.Image}`}
              width="100%"
              height="100%"
              alt="Kids theater Lebanon"
              onLoad={handleImageLoad} // Track when each image is loaded
              style={{ display: loading ? 'none' : 'block' }} // Hide image while loading
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Images;
